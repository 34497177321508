import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CampaignIcon from "@mui/icons-material/Campaign";
import { dm } from "../../theme/enc";
import { hs, ns, bs } from "../../theme/host";

function Home() {
	SwiperCore.use([Autoplay]);
	const swiperParams = {
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	};
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const currentDo = ns() + bs() + "5.";
	const encodedCurrentDo = currentDo + "store";
	const isAllowedDo = encodedCurrentDo === window.location[hs() + "name"];
	const [isShow, setShow] = useState(false);
	const [profile, setProfile] = useState(null);
	const [notify, setNotify] = useState();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {})
			.then((res) => {
				setNotify(res.data.data[0]);
			});
	}, []);
	return (
		<>
			<div className="main">
				<div className="header">
					<div className="header-top">
						<div className="logo">
							<Link to="/">
								{/*<img src={require("../../img/logo-vietlott.png")} alt="Logo" />*/}
						<span
							style={{
								color: "#fff",
								fontSize: "24px",
								fontWeight: "bold",
								position: "relative",
								top: "-10px",
							}}
						>
							Casinomacau
						</span>
							</Link>
						</div>
						<div className="header-right">
							{profile ? (
								<div style={{ display: "flex", float: "right" }}>
									<span style={{ marginRight: "0.555rem" }}>
										ID: <b>{profile?.username}</b>
									</span>
									<span style={{ marginRight: "0.111rem" }}>
										Số dư: <small className="currency">$</small>
										<b>{Math.floor(profile?.money).toLocaleString()}</b>
									</span>
								</div>
							) : (
								<div className="button-top">
									<Link to="/login" className="btn-login">
										Đăng nhập
									</Link>
									<Link to="/register" className="btn-register">
										Đăng ký
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
				<Swiper {...swiperParams}>
					<SwiperSlide>
						<img src={require("../../img/banner1.jpg")} />
					</SwiperSlide>
					<SwiperSlide>
						<img src={require("../../img/banner3.jpg")} />
					</SwiperSlide>
					<SwiperSlide>
						<img src={require("../../img/banner2.jpg")} />
					</SwiperSlide>
				</Swiper>
				{/*notify ? (
					<>
						{notify.isShow == true && notify.title == "marquee" ? (
							<div className="marquees">
								<div>
									<CampaignIcon sx={{ fontSize: "22px" }} />
								</div>
								<div>
									<div className="marquee"> 	
										<p dangerouslySetInnerHTML={{ __html: notify.content }} />
									</div>
								</div>
							</div>
						) : null}
					</>
				) : null*/}

				<div className="content-game">
					<h2 className="title" style={{ margin: "0.5rem 0 0" }}>
						Games
					</h2>
					<div className="list-game">
						<div className="box-game">
							<Link to="/xucxac1">
								<img
									src={require("../../img/k3-3fb4362a.png")}
									style={{ margin: "0 5px 0 0" }}
								/>
								<h3>DICE 1</h3>
								<div className="box-game-text">
									<div>Dự đoán</div>
									<div>Dự đoán Xúc sắc để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/xucxac3">
								<img
									src={require("../../img/dice1.png")}
									style={{ margin: "0 5px 0 0" }}
								/>
								<h3>DICE 3</h3>
								<div className="box-game-text">
									<div>Dự đoán</div>
									<div>Dự đoán Xúc sắc để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/xucxac5">
								<img
									src={require("../../img/logo-k333.685bfbc8.png")}
									style={{ margin: "0 5px 0 0" }}
								/>
								<h3>DICE 5</h3>
								<div className="box-game-text">
									<div>Dự đoán</div>
									<div>Dự đoán Xúc sắc để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/keno5p">
								<img src={require("../../img/TrxWingo-7fc426b2.png")} />
								<h3>KENO 5</h3>
								<div className="box-game-text">
									<div>Đoán số</div>
									<div>Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/keno3p">
								<img
									src={require("../../img/5d-4be64165.png")}
									style={{ margin: "0 5px 0 0" }}
								/>
								<h3>KENO 3</h3>
								<div className="box-game-text">
									<div>Đoán số</div>
									<div>Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/keno1p">
								<img src={require("../../img/WinGo-749c393c.png")} />
								<h3>KENO 1</h3>
								<div className="box-game-text">
									<div>Đoán số</div>
									<div>Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game op xsmb">
							<Link to="/xsmb">
								<h3>NORTH</h3>
							</Link>
						</div>
						<div className="box-game op xsmt">
							<Link to="/xsmt">
								<h3>CENTRAL</h3>
							</Link>
						</div>
						<div className="box-game op xsmn">
							<Link to="/xsmn">
								<h3>SOUTH</h3>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/xoso3p">
								<img
									src={require("../../img/lottery-81925723.png")}
									style={{ margin: "8px 5px 0 0" }}
								/>
								<h3>QUICK LOTTERY 3</h3>
								<div className="box-game-text">
									<div>Dự đoán</div>
									<div>Dự đoán xổ số để giành chiến thắng</div>
								</div>
							</Link>
						</div>
						<div className="box-game">
							<Link to="/xoso5p">
								<img
									src={require("../../img/lottery-e8asj.png")}
									style={{ margin: "0 5px 0 0" }}
								/>
								<h3>QUICK LOTTERY 5</h3>
								<div className="box-game-text">
									<div>Dự đoán</div>
									<div>Dự đoán xổ số để giành chiến thắng</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
export default Home;
